.Main{
  padding: 40px 20px;
  max-width: 400px;
  margin: 0 auto;
}
@media screen and (min-width: 500px){
  .Main{
    max-width: 600px;
  }
}
@media screen and (min-width: 720px){
  .Main{
    max-width: 720px;
  }
}
@media screen and (min-width: 900px){
  .Main{
    max-width: none;
  }
}