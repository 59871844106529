.Hero{
  &-banner{
    width: 100%;
    height: calc(65vh);
    position: relative;
  }
  &-slider{
    position: relative;
    width: 100%;
    height: 100%;
  }
  &-item{
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    transition: width 2s;
    overflow: hidden;
    &.disabled{
      width: 0px;
    }
  }
  &-container{
    width: 100vw;
    height: 100%;
    position: absolute;
    picture{
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
      transition: transform .5s;
      img{
        width: 100vw;
        height: 100%;
        object-fit: cover;
      }
    }
    &:hover{
      picture{
        transform: scale(1.1);
      }
    }
  }
  &-opacity{
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
  &-content{
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-title{
    margin-bottom: 10px;
  }
  &-tags{
    text-align: right;
    margin-top: 10px;
    max-width: 500px;
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
    bottom: 0;
    right: 0;
    padding: 20px;
  }
  /* &-title{
    font-family: var(--second-font), var(--main-font);
    color: white;
    font-weight: bold;
    text-align: right;
    position: absolute;
    padding: 1em;
    bottom: 3em;
    right: 2em;
    font-size: 2em;
    width: 360px;
    line-height: 1.32em;
    text-shadow: 0 0 10px black;
  } */
  &-handlers{
    position: absolute;
    bottom: 3em;
    left: 50%;
    display: flex;
    transform: translateX(-50%);
  }
  &-handler{
    margin-left: 1em;
    width: 50px;
    height: 6px;
    background-color: var(--bg-color);
    &:nth-child(1){
      margin-left: 0;
    }
    &.is-active{
      background-color: var(--main-color);
    }
  }
}