.Section{
  &-title{
    text-transform: uppercase;
    color: var(--dark);
    margin-bottom: 10px;
  }
  &-btn{
    text-align: center;
    margin-top: 0px;
  }
}