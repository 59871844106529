.Hero {
  /* &-title{
    font-family: var(--second-font), var(--main-font);
    color: white;
    font-weight: bold;
    text-align: right;
    position: absolute;
    padding: 1em;
    bottom: 3em;
    right: 2em;
    font-size: 2em;
    width: 360px;
    line-height: 1.32em;
    text-shadow: 0 0 10px black;
  } */ }
  .Hero-banner {
    width: 100%;
    height: calc(65vh);
    position: relative; }
  .Hero-slider {
    position: relative;
    width: 100%;
    height: 100%; }
  .Hero-item {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    transition: width 2s;
    overflow: hidden; }
    .Hero-item.disabled {
      width: 0px; }
  .Hero-container {
    width: 100vw;
    height: 100%;
    position: absolute; }
    .Hero-container picture {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
      transition: transform .5s; }
      .Hero-container picture img {
        width: 100vw;
        height: 100%;
        object-fit: cover; }
    .Hero-container:hover picture {
      transform: scale(1.1); }
  .Hero-opacity {
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5); }
  .Hero-content {
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center; }
  .Hero-title {
    margin-bottom: 10px; }
  .Hero-tags {
    text-align: right;
    margin-top: 10px;
    max-width: 500px;
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
    bottom: 0;
    right: 0;
    padding: 20px; }
  .Hero-handlers {
    position: absolute;
    bottom: 3em;
    left: 50%;
    display: flex;
    transform: translateX(-50%); }
  .Hero-handler {
    margin-left: 1em;
    width: 50px;
    height: 6px;
    background-color: var(--bg-color); }
    .Hero-handler:nth-child(1) {
      margin-left: 0; }
    .Hero-handler.is-active {
      background-color: var(--main-color); }

.Main {
  padding: 40px 20px;
  max-width: 400px;
  margin: 0 auto; }

@media screen and (min-width: 500px) {
  .Main {
    max-width: 600px; } }

@media screen and (min-width: 720px) {
  .Main {
    max-width: 720px; } }

@media screen and (min-width: 900px) {
  .Main {
    max-width: none; } }

.masonry-layout {
  --columns: 1;
  --gap: 20px;
  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);
  grid-gap: var(--gap); }
  .masonry-layout .masonry-item {
    margin-bottom: var(--gap); }
  .masonry-layout.columns-1 {
    --columns: 1; }
  .masonry-layout.columns-2 {
    --columns: 2; }
  .masonry-layout.columns-3 {
    --columns: 3; }
  .masonry-layout.columns-4 {
    --columns: 4; }
  .masonry-layout.columns-5 {
    --columns: 5; }
  .masonry-layout.columns-6 {
    --columns: 6; }

.Section-title {
  text-transform: uppercase;
  color: var(--dark);
  margin-bottom: 10px; }

.Section-btn {
  text-align: center;
  margin-top: 0px; }

@media screen and (min-width: 900px) {
  .Main {
    padding: 40px 50px;
    display: grid;
    grid-gap: 40px 40px;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto auto;
    grid-auto-rows: auto;
    grid-template-areas: "PopularSections PopularSections" "ColIzq ColDer"; } }

@media screen and (min-width: 1200px) {
  .Main {
    padding: 40px 0;
    max-width: 1024px;
    margin: 0 auto; } }

@media screen and (min-width: 900px) {
  .Section-Sections {
    grid-area: PopularSections; } }

.ColIzq {
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-auto-rows: auto; }

@media screen and (min-width: 900px) {
  .ColIzq {
    display: grid;
    grid-gap: 40px;
    grid-area: ColIzq;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-auto-rows: auto; }
    .ColIzq .Search {
      display: none; } }

.ColDer {
  margin-top: 40px;
  display: grid;
  grid-gap: 40px 20px;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-auto-rows: auto;
  grid-template-areas: "FanPage" "TagsSect" "SocialMedia" "Contact"; }
  .ColDer .Search {
    display: none; }
  .ColDer .Section-Documents--aux {
    display: none; }

@media screen and (min-width: 500px) {
  .ColDer {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-auto-rows: auto;
    grid-template-areas: "Contact Contact" "TagsSect TagsSect" "FanPage SocialMedia"; } }

@media screen and (min-width: 900px) {
  .ColDer {
    margin-top: 0;
    grid-area: ColDer;
    grid-template-columns: 1fr;
    align-items: start;
    align-content: start;
    grid-template-areas: "Search" "Documents" "TagsSect" "FanPage" "SocialMedia" "Contact"; }
    .ColDer .Search {
      grid-area: Search;
      display: flex; }
    .ColDer .Section-Documents--aux {
      display: block; } }

.PopularSections {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  grid-auto-rows: auto;
  grid-gap: 10px 20px;
  margin-bottom: 40px; }
  .PopularSections a {
    border-left: 8px solid var(--dark);
    background-color: var(--blank);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.18);
    color: var(--dark); }
  .PopularSections div {
    display: flex;
    padding: 10px;
    width: 100%;
    align-items: center; }
  .PopularSections i {
    margin-right: 0.5em;
    font-size: 2em; }

@media screen and (min-width: 500px) {
  .PopularSections {
    grid-template-columns: 1fr 1fr; } }

@media screen and (min-width: 720px) {
  .PopularSections {
    grid-template-columns: repeat(3, 1fr); } }

@media screen and (min-width: 900px) {
  .PopularSections {
    margin-bottom: 0; } }

@media screen and (min-width: 1024px) {
  .PopularSections {
    grid-template-columns: repeat(4, 1fr); } }

@media screen and (min-width: 500px) {
  .Section {
    margin: 0; }
    .Section-Documents--aux {
      grid-area: Documents; }
    .Section-FanPage {
      grid-area: FanPage; }
    .Section-SocialMedia {
      grid-area: SocialMedia; }
    .Section-TagsSect {
      grid-area: TagsSect; }
    .Section-Contact {
      grid-area: Contact; } }

.News {
  padding: 0; }
  .News article {
    box-shadow: 0 3px 6px var(--shadow);
    cursor: pointer;
    border-radius: 2px; }
    .News article div {
      width: 100%;
      overflow: hidden; }
    .News article img {
      border-radius: 2px 2px 0 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform var(--time-transition); }
    .News article h3 {
      padding: 0 20px;
      margin: 10px 0;
      color: var(--dark);
      transition: color var(--time-transition); }
    .News article p {
      padding: 0 20px;
      color: var(--dark);
      margin-bottom: 10px;
      transition: color var(--time-transition); }
    .News article ul {
      padding: 0 20px 20px; }
    .News article:hover h2, .News article:hover p {
      color: var(--middle); }
    .News article:hover img {
      transform: scale(1.1); }

.Documents {
  padding: 0; }
  .Documents article {
    box-shadow: 0 3px 6px var(--shadow);
    cursor: pointer;
    border-radius: 2px;
    background-color: var(--blank); }
    .Documents article div {
      width: 100%;
      overflow: hidden; }
    .Documents article img {
      border-radius: 2px 2px 0 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform var(--time-transition); }
    .Documents article h3 {
      padding: 20px 20px 0;
      margin: 10px 0;
      color: var(--dark);
      transition: color var(--time-transition); }
    .Documents article p {
      padding: 0 20px;
      color: var(--dark);
      margin-bottom: 10px;
      transition: color var(--time-transition); }
    .Documents article ul {
      padding: 0 20px 20px; }
    .Documents article:hover h2, .Documents article:hover p {
      color: var(--middle); }
    .Documents article:hover img {
      transform: scale(1.1); }

.Documents--aux {
  padding: 0;
  padding: 10px 20px;
  box-shadow: 0 3px 6px var(--shadow);
  background-color: var(--blank);
  border-radius: 2px; }
  .Documents--aux article {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--dark);
    cursor: pointer; }
    .Documents--aux article p {
      margin: 5px 0;
      color: var(--dark);
      transition: color var(--time-transition); }
    .Documents--aux article:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0; }
    .Documents--aux article:hover h2, .Documents--aux article:hover p {
      color: var(--middle); }
    .Documents--aux article:hover img {
      transform: scale(1.1); }
