//MAIN DEL HOME
@media screen and (min-width: 900px){
  .Main{
    padding: 40px 50px;
    display: grid;
    grid-gap: 40px 40px;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto auto;
    grid-auto-rows: auto;
    grid-template-areas: 
      "PopularSections PopularSections"
      "ColIzq ColDer";
  }
}
@media screen and (min-width: 1200px){
  .Main{
    padding: 40px 0;
    max-width: 1024px;
    margin: 0 auto;
  }
}
//COLUMNAS
@media screen and (min-width: 900px){
  .Section-Sections{
    grid-area: PopularSections;
  }
}
.ColIzq{
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-auto-rows: auto;
}
@media screen and (min-width: 900px){
  .ColIzq{
    display: grid;
    grid-gap: 40px;
    grid-area: ColIzq;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-auto-rows: auto;
    .Search{
      display: none;
    }
  }
}
.ColDer{
  .Search{
    display: none;
  }
  .Section-Documents--aux{
    display: none;
  }
  margin-top: 40px;
  display: grid;
  grid-gap: 40px 20px;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-auto-rows: auto;
  grid-template-areas:
    "FanPage"
    "TagsSect"
    "SocialMedia"
    "Contact";
}
@media screen and (min-width: 500px){
  .ColDer{
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-auto-rows: auto;
    grid-template-areas:
      "Contact Contact"
      "TagsSect TagsSect"
      "FanPage SocialMedia";
  }
}
@media screen and (min-width: 900px){
  .ColDer{
    margin-top: 0;
    grid-area: ColDer;
    grid-template-columns: 1fr;
    align-items: start;
    align-content: start;
    grid-template-areas:
      "Search"
      "Documents"
      "TagsSect"
      "FanPage"
      "SocialMedia"
      "Contact";
    .Search{
      grid-area: Search;
      display: flex;
    }
    .Section-Documents--aux{
      display: block;
    }
  }
}
//SECCIONES POPULARES (ssoma v1)
.PopularSections{
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  grid-auto-rows: auto;
  grid-gap: 10px 20px;
  margin-bottom: 40px;
  a{
    border-left: 8px solid var(--dark);
    background-color: var(--blank);
    box-shadow: 0 3px 6px rgba(0, 0, 0, .18);
    color: var(--dark);
  }
  div{
    display: flex;
    padding: 10px;
    width: 100%;
    align-items: center;
  }
  i{
    margin-right: 0.5em;
    font-size: 2em;
  }
}
@media screen and (min-width: 500px){
  .PopularSections{
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 720px){
  .PopularSections{
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (min-width: 900px){
  .PopularSections{
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1024px){
  .PopularSections{
    grid-template-columns: repeat(4, 1fr);
  }
}
//SECTIONS DEL HOME
@media screen and (min-width: 500px){
  .Section{
    margin: 0;
    &-Documents--aux{
      grid-area: Documents;
    }
    &-FanPage{
      grid-area: FanPage;
    }
    &-SocialMedia{
      grid-area: SocialMedia;
    }
    &-TagsSect{
      grid-area: TagsSect;
    }
    &-Contact{
      grid-area: Contact;
    }
  }
}
//SECCIÓN DE NOTICIAS DEL INDEX
.News{
  padding: 0;
  article{
    box-shadow: 0 3px 6px var(--shadow);
    cursor: pointer;
    border-radius: 2px;
    div{
      width: 100%;
      overflow: hidden;
    }
    img{
      border-radius: 2px 2px 0 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform var(--time-transition);
    }
    h3{
      padding: 0 20px;
      margin: 10px 0;
      color: var(--dark);
      transition: color var(--time-transition);
    }
    p{
      padding: 0 20px;
      color: var(--dark);
      margin-bottom: 10px;
      transition: color var(--time-transition);
    }
    ul{
      padding: 0 20px 20px;
    }
    &:hover{
      h2, p{
        color: var(--middle);
      }
      img{
        transform: scale(1.1);
      }
    }
  }
}
//SECCION DE DOCUMENTOS DEL INDEX
.Documents{
  padding: 0;
  article{
    box-shadow: 0 3px 6px var(--shadow);
    cursor: pointer;
    border-radius: 2px;
    background-color: var(--blank);
    div{
      width: 100%;
      overflow: hidden;
    }
    img{
      border-radius: 2px 2px 0 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform var(--time-transition);
    }
    h3{
      padding: 20px 20px 0;
      margin: 10px 0;
      color: var(--dark);
      transition: color var(--time-transition);
    }
    p{
      padding: 0 20px;
      color: var(--dark);
      margin-bottom: 10px;
      transition: color var(--time-transition);
    }
    ul{
      padding: 0 20px 20px;
    }
    &:hover{
      h2, p{
        color: var(--middle);
      }
      img{
        transform: scale(1.1);
      }
    }
  }
}
.Documents--aux{
  padding: 0;
  padding: 10px 20px;
  box-shadow: 0 3px 6px var(--shadow);
  background-color: var(--blank);
  border-radius: 2px;
  article{
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--dark);
    cursor: pointer;
    p{
      margin: 5px 0;
      color: var(--dark);
      transition: color var(--time-transition);
    }
    ul{
    }
    &:last-child{
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }
    &:hover{
      h2, p{
        color: var(--middle);
      }
      img{
        transform: scale(1.1);
      }
    }
  }
}